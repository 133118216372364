import cn from 'classnames'
import {
    MdCheckCircleOutline,
    MdInfoOutline,
    MdOutlineNotStarted,
    MdOutlineStopCircle,
    MdRemoveShoppingCart,
    MdDeleteSweep,
} from 'react-icons/md'

const QueueNavbar = ({
    vm,
}) => {
    
    return (
        
        <nav className="flex justify-between items-bottom content-bottom">
            
            <div className="flex items-bottom content-bottom">
                <div className="flex w-fit tabs tabs-boxed" role="tablist">
                    {Object.keys(vm.TABS).map(it => (
                        <button
                            key={`tab-${it}`}
                            className={cn('flex btn w-auto tooltip tooltip-primary tooltip-bottom', {
                                'tab-active': vm.tab === vm.TABS[it],
                            })}
                            role="tab"
                            data-tip={it}
                            onClick={() => vm.setTab(vm.TABS[it])}>
                            {vm.tabIconFor(vm.TABS[it])}
                            <span className="hidden xl:block">{it}</span>
                        </button>
                    ))}
                </div>
                <div className="flex items-center content-center ml-4 text-sm font-bold text-base-content">
                    SHOWING {vm.visibleLimit}/{vm.totalJobsLabel} TOTAL ITEMS
                </div>
            </div>
            
            <div className="flex justify-end gap-2 p-1 items-bottom content-bottom">
                
                <button
                    className="p-0 btn btn-link tooltip tooltip-primary tooltip-bottom"
                    data-tip="Info"
                    onClick={() => vm.setQueueInfoModalOpen(true)}>
                    <MdInfoOutline className="w-auto mx-auto text-4xl
                            cursor-pointer text-info-content/60 hover:text-info-content"/>
                </button>
                
                {vm.isSysAdmin && (
                    <button
                        className="
                                flex items-center content-center justify-center
                                w-auto text-warning-content btn btn-warning
                                tooltip tooltip-primary tooltip-bottom"
                        data-tip="SCHEDULE HEALTH CHECK"
                        onClick={vm.onScheduleQueueHealthCheckClick}>
                        <MdCheckCircleOutline/>
                        <span className="hidden xl:block">SCHEDULE HEALTH CHECK</span>
                    </button>
                )}
                
                {vm.isSysAdmin && (
                    <button
                        className="
                                flex items-center content-center justify-center
                                w-auto text-warning-content btn btn-warning
                                tooltip tooltip-primary tooltip-bottom"
                        data-tip="PRUNE QUEUE AUDITS"
                        onClick={vm.onPruneQueueAuditsClick}>
                        <MdRemoveShoppingCart/>
                        <span className="hidden xl:block">PRUNE QUEUE AUDITS</span>
                    </button>
                )}
                
                <button
                    className={cn('btn w-auto',
                        'flex justify-center items-center content-center ',
                        'tooltip tooltip-primary tooltip-bottom', {
                            'btn-error text-red-400': vm.stats?.isRunning,
                            'btn-info text-info-content': !vm.stats?.isRunning,
                        },
                    )}
                    data-tip={`${(vm.stats?.isRunning ? 'STOP' : 'START')} QUEUE`}
                    onClick={vm.onToggleQueueClick}>
                    {vm.stats?.isRunning
                        ? <MdOutlineStopCircle className="text-2xl" />
                        : <MdOutlineNotStarted className="text-2xl" />}
                    <span className="hidden xl:block ml-3">
                        {vm.stats?.isRunning ? 'STOP' : 'START'} QUEUE
                    </span>
                </button>
                
                <button
                    className="btn w-auto
                        flex justify-center items-center content-center
                        tooltip tooltip-primary tooltip-bottom btn-error text-red-400"
                    data-tip="CLEAR QUEUE"
                    onClick={vm.onClearQueueClick}>
                    <MdDeleteSweep className="text-2xl" />
                    <span className="hidden xl:block ml-3">
                        CLEAR QUEUE
                    </span>
                </button>
            
            </div>
        
        </nav>
        
    )
    
}

export default QueueNavbar
