import { useState, useEffect, useMemo, useCallback } from 'react'
import * as actions from '@graphql/actions'
import useUserHasRoles from '@hook/useUserHasRoles'
import { ROLE_SYS_ADMIN } from '@constants/roles'
import { zeroPad } from '@utils'
import { toast } from 'react-toastify'

import { MdSecurity, MdWork, MdArchive } from 'react-icons/md'

const TABS = {
    AUDITS: 1,
    JOBS: 2,
    ARCHIVED: 3,
}

const TAB_ICONS = {
    [TABS.AUDITS]: MdSecurity,
    [TABS.JOBS]: MdWork,
    [TABS.ARCHIVED]: MdArchive,
}

const jobsLimit = 20
const auditsLimit = 50

const limitsMap = {
    [TABS.AUDITS]: auditsLimit,
    [TABS.JOBS]: jobsLimit,
    [TABS.ARCHIVED]: jobsLimit,
}

const QueueViewModel = () => {
    
    const [tab, setTab] = useState(TABS.AUDITS)
    const [stats, setStats] = useState(null)
    const [totalAudits, setTotalAudits] = useState(0)
    const [oldAuditsCount, setOldAuditsCount] = useState(0)
    const [totalJobs, setTotalJobs] = useState(0)
    const [filterName, setFilterName] = useState('parse')
    const [monitorJobsEnabled, setMonitorJobsEnabled] = useState(true)
    const [hideHealthChecks, setHideHealthChecks] = useState(true)
    const [queueInfoModalOpen, setQueueInfoModalOpen] = useState(false)
    const [confirmPruneQueueAuditsModalOpen, setConfirmPruneQueueAuditsModalOpen] = useState(false)
    
    // Just for display purposes
    const visibleLimit = useMemo(() => {
        
        let value = 0
        const total = limitsMap[tab]
        
        switch (tab) {
            case TABS.AUDITS:
                value = totalAudits
                break
            case TABS.JOBS:
            case TABS.ARCHIVED:
                value = totalJobs
                break
        }
        
        return Math.min(value, total)
        
    }, [tab, totalAudits, totalJobs])
    
    const totalJobsLabel = useMemo(() => zeroPad(
        tab === TABS.AUDITS ? totalAudits : totalJobs,
    ), [tab, totalAudits, totalJobs])
    
    const isSysAdmin = useUserHasRoles(ROLE_SYS_ADMIN)
    
    const onToggleQueueClick = useCallback(async () => {
        
        try {
            
            const res = await actions.toggleQueueEnabled()
            
            setStats(res?.data ?? null)
            
        } catch (e) {
            
            // @todo show error
            console.log('onToggleQueueClick', e)
            toast.error(e?.message ?? 'Unknown error')
            
        }
        
    }, [])
    
    const onClearQueueClick = useCallback(async () => {
        
        try {
            
            if (!confirm('Are you sure you want to clear the entire queue & all audits?'))
                return
            
            await actions.clearQueue()
            
            toast.success('Queue cleared')
            
        } catch (e) {
            
            console.error('onClearQueueClick', e)
            toast.error('Failed to clear queue')
            
        }
        
    }, [])
    
    const onScheduleQueueHealthCheckClick = useCallback(async () => {
        
        try {
            
            await actions.scheduleQueueHealthCheck()
            
            toast.success('Manual queue health check scheduled')
            
        } catch (e) {
            
            console.error('onScheduleQueueHealthCheckClick', e)
            toast.error('Failed to schedule queue health check')
            
        }
        
    }, [])
    
    const onPruneQueueAuditsClick = useCallback(async () => {
        
        try {
            
            const res = await actions.fetchPruneQueueAuditsCount()
            
            setOldAuditsCount(res.count)
            setConfirmPruneQueueAuditsModalOpen(true)
            
        } catch (e) {
            
            console.error('onPruneQueueAuditsClick', e)
            toast.error('Failed to prune queue audits')
            
        }
        
    }, [])
    
    const pruneQueueAudits = useCallback(async () => {
        
        try {
            
            await actions.pruneQueueAudits()
            
            setOldAuditsCount(0)
            toast.success(`Pruned ${oldAuditsCount} stale queue audit logs`)
            
        } catch (e) {
            
            console.error('pruneQueueAudits', e)
            toast.error('Failed to prune queue audits')
            
        }
        
    }, [oldAuditsCount])
    
    const tabIconFor = tab => {
        
        const Icon = TAB_ICONS[tab]
        
        return <Icon />
        
    }
    
    useEffect(() => {
        actions.fetchStats()
            .then(it => setStats(it?.data ?? null))
            .catch(e => {
                console.error('@todo show fetchStats error', e)
                toast.error('Failed to fetch queue stats')
            })
    }, [])
    
    return {
        
        // Constants
        TABS,
        TAB_ICONS,
        jobsLimit,
        auditsLimit,
        limitsMap,
        
        // State
        tab,
        setTab,
        stats,
        setStats,
        totalAudits,
        setTotalAudits,
        oldAuditsCount,
        setOldAuditsCount,
        totalJobs,
        setTotalJobs,
        filterName,
        setFilterName,
        monitorJobsEnabled,
        setMonitorJobsEnabled,
        hideHealthChecks,
        setHideHealthChecks,
        queueInfoModalOpen,
        setQueueInfoModalOpen,
        confirmPruneQueueAuditsModalOpen,
        setConfirmPruneQueueAuditsModalOpen,
        
        // Memos
        visibleLimit,
        totalJobsLabel,
        isSysAdmin,
        
        // Methods
        onToggleQueueClick,
        onClearQueueClick,
        onScheduleQueueHealthCheckClick,
        onPruneQueueAuditsClick,
        pruneQueueAudits,
        tabIconFor,
        
    }
    
}

export default QueueViewModel
