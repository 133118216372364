import useQueueViewModel from './QueueViewModel'
import cn from 'classnames'

import QueueNavbar from './QueueNavbar'
import AuditsTable from '$components/Queue/AuditsTable'
import JobsTable from '$components/Queue/JobsTable'
import ConfirmationModal from '@components/shared/ConfirmationModal'

import './Queue.css'

const Queue = () => {
    
    const vm = useQueueViewModel()
    
    return (
        
        <div className="Queue">
            
            <QueueNavbar vm={vm} />
            
            <header className="flex items-center content-center justify-between mt-4">
                
                <div className="flex items-center content-center justify-end">
                    
                    <label className={cn('flex items-center content-center justify-center', {
                        'opacity-40': vm.tab === vm.TABS.AUDITS,
                    })}>
                        <span>Only Parse Jobs</span>
                        <input
                            className="ml-3"
                            type="checkbox"
                            checked={vm.filterName}
                            disabled={vm.tab === vm.TABS.AUDITS}
                            onChange={e => vm.setFilterName(e.target.checked)}/>
                    </label>
                    
                    <label className="flex items-center content-center justify-center ml-4">
                        <span>Monitor Jobs</span>
                        <input
                            className="ml-3"
                            type="checkbox"
                            checked={vm.monitorJobsEnabled}
                            onChange={e => vm.setMonitorJobsEnabled(e.target.checked)}/>
                    </label>
                    
                    <label className="flex items-center content-center justify-center ml-4">
                        <span>Hide Health Checks</span>
                        <input
                            className="ml-3"
                            type="checkbox"
                            checked={vm.hideHealthChecks}
                            onChange={e => vm.setHideHealthChecks(e.target.checked)}/>
                    </label>
                
                </div>
            
            </header>
            
            {vm.tab === vm.TABS.AUDITS ? (
                <AuditsTable
                    auditsLimit={vm.auditsLimit}
                    monitorJobsEnabled={vm.monitorJobsEnabled}
                    setTotalAudits={vm.setTotalAudits}
                    hideHealthChecks={vm.hideHealthChecks}/>
            ) : (
                <JobsTable
                    TABS={vm.TABS}
                    tab={vm.tab}
                    stats={vm.stats}
                    filterName={vm.filterName}
                    jobsLimit={vm.jobsLimit}
                    monitorJobsEnabled={vm.monitorJobsEnabled}
                    setTotalJobs={vm.setTotalJobs}/>
            )}
            
            <ConfirmationModal
                showModal={vm.queueInfoModalOpen}
                showHideModal={vm.setQueueInfoModalOpen}
                title="Parse Queue"
                message={(
                    <div className="flex flex-col gap-4">
                        <div className="text-base">
                            <h4 className="text-xl">Audits</h4>
                            <p>
                                Audits are a more simple &amp; clean way to view queue job statuses.
                                <br/>
                                Jobs are grouped by <code>objectId</code> &amp; <code>jobType</code>,
                                and each group has a unique color-coded border for easy identification.
                                An <code>objectId</code> is the ID of the object the queue is working on &mdash;
                                for example, a document ID or check ID.
                            </p>
                            <p>
                                Jobs that succeed are marked with a green background, and failed jobs with red.
                                If a job reports an error, that will be shown. Most jobs also have a data
                                payload, which will also be visible (collapsed by default).
                            </p>
                        </div>
                        <div className="mt-4 text-base">
                            <h4 className="text-xl">Jobs</h4>
                            <p>
                                Jobs are the raw feed from the queue.
                                <br/>
                                This list is often messy &amp; difficult to read, but can be helpful for debugging.
                            </p>
                        </div>
                        <div className="mt-4 text-base">
                            <h4 className="text-xl">Archived</h4>
                            <p>
                                Archived jobs are the raw feed of expired jobs.
                            </p>
                        </div>
                    </div>
                )}/>
            
            <ConfirmationModal
                showModal={vm.confirmPruneQueueAuditsModalOpen}
                showHideModal={vm.setConfirmPruneQueueAuditsModalOpen}
                title="Prune Queue Audits"
                onConfirm={vm.pruneQueueAudits}
                message={(
                    <div>
                        <div className="text-base">
                            <h4 className="text-xl">Prune Queue Audits</h4>
                            <p>
                                Are you sure you want to prune {vm.oldAuditsCount} queue audits?
                            </p>
                        </div>
                    </div>
                )}/>
        
        </div>
        
    )
    
}

export default Queue
